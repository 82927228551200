
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IOwnerRelation, IProposalStatus } from '@/types/Interfaces';
import { cloneDeep, conformsTo } from 'lodash';
import useOwnerRelation from '@/types/OwnerRelation';

export default defineComponent({
  components: {
  },
  props: {
    modelValue: {
      type: Object as () => IProposalStatus
    }
  },
  setup(props: any, context: SetupContext) {
    const {
      createOwnerRelation,
      updateOwnerRelation
    } = useOwnerRelation();

    const data = ref<IOwnerRelation | null>(null);
    const loading = ref(false);

    const onClose = () => {
      context.emit('close');  
    };
    const onUpdate = async () => {
      // do some async work
      loading.value = true;
      if (data.value == null) return;
      let or;
      if (data.value.id) {
        // update
        or = await updateOwnerRelation(data.value.id, data.value)
      } else {
        // cretae
        or = await createOwnerRelation(data.value)
        console.log('created')
      }
      console.log(or)
      context.emit('update', data.value);
      loading.value = false;
    };

    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
    })

    return {
      loading,
      data,
      onUpdate,
      onClose
    }
  }
})
