<template>
  <div class="text-gray-900 inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-6  pb-4 sm:p-6 sm:pb-4">
      <form
        @submit.prevent="onUpdate"
        v-if="data != null"
        class="pt-6"
      >
        <text-field
          v-model="data.relation"
          label="関係"
          class="mb-5"
        ></text-field>
        <text-field
          v-model="data.sort"
          label="ソート"
          class="mb-5"
        ></text-field>  
        <div class="text-left">
          <span class="text-sm ml-2">表示</span>
          <my-toggle
          v-model="data.display"
          ></my-toggle>
            
        </div>
        <div>
          <button
            class="success"
            @click="update"
          >
            {{ data.id ? '更新' : '追加' }}
          </button>
          <button class="ml-5" @click="onClose">
            閉じる
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, SetupContext, } from 'vue';
import { IOwnerRelation, IProposalStatus } from '@/types/Interfaces';
import { cloneDeep, conformsTo } from 'lodash';
import useOwnerRelation from '@/types/OwnerRelation';

export default defineComponent({
  components: {
  },
  props: {
    modelValue: {
      type: Object as () => IProposalStatus
    }
  },
  setup(props: any, context: SetupContext) {
    const {
      createOwnerRelation,
      updateOwnerRelation
    } = useOwnerRelation();

    const data = ref<IOwnerRelation | null>(null);
    const loading = ref(false);

    const onClose = () => {
      context.emit('close');  
    };
    const onUpdate = async () => {
      // do some async work
      loading.value = true;
      if (data.value == null) return;
      let or;
      if (data.value.id) {
        // update
        or = await updateOwnerRelation(data.value.id, data.value)
      } else {
        // cretae
        or = await createOwnerRelation(data.value)
        console.log('created')
      }
      console.log(or)
      context.emit('update', data.value);
      loading.value = false;
    };

    onMounted(() => {
      data.value = cloneDeep(props.modelValue);
    })

    return {
      loading,
      data,
      onUpdate,
      onClose
    }
  }
})
</script>
