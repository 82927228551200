<template>
  <base-layout>

    <template v-slot:title>
      <div
        class="text-lg "
      >
        オーナ様との関係マスタ
      </div>
    </template>
    <div class="p-3">
      
      <div class="text-right mb-3">
        <button
          @click="editingItem = addTemplate"
          class="success">
          新規作成
        </button>
      </div>
      <div>
        <table
          class="base"
        >
          <thead>
            <tr>
              <th>関係</th>
              <th>ソート順</th>
              <th>表示</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="data">
            <tr
              v-for="(p, i) in data"
              :key="i"
              
            >
              <td class="">
                {{ p.relation }}
              </td>
              <td>{{ p.sort }}</td>
              <td>
                <my-toggle
                  :modelValue="p.display"
                ></my-toggle>
              </td>
              <td>
                <div
                  class="flex justify-end"  
                >
                  <svg
                    @click="editingItem = p"
                    class="w-6 h-6 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                  <svg
                    @click="onDelete(p.id)"
                    class="w-6 h-6 cursor-pointer  ml-2 text-red-600"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                
              </td>
            </tr>
          </tbody>
        </table>
        <frame-modal
          v-if="editingItem != null"
        >
          <owner-relation-edit-form
            :modelValue = editingItem
            @update="onUpdate"
            @close="editingItem = null"
          ></owner-relation-edit-form>
        </frame-modal>
      </div>
    </div>
  </base-layout>
</template>
<script lang="ts">
import { computed, defineComponent, isProxy, onMounted, ref, SetupContext, } from 'vue';
import { IOwnerRelation, IPurpose } from '@/types/Interfaces';
import useOwnerRelation from '@/types/OwnerRelation';
import useMasterData from '@/types/MasterData';

import OwnerRelationEditForm from './OwnerRelationEditForm.vue';
import useFlashMessage from '@/types/FlashMessage';


export default defineComponent({
  components: {
    OwnerRelationEditForm
  },
  setup(_, context: SetupContext) {
    // const headers = g
    const {
      setMessage   
    } = useFlashMessage();

    const {
      data,
      editingItem,
      onChangeDisplay
    } = useMasterData();
    
    
    const {
      fetchOwnerRelations,
      deleteOwnerRelation
    } = useOwnerRelation();
    
    onMounted(async () => {
      data.value = await fetchOwnerRelations();
      console.log(data.value);
    })
    
    const addTemplate: IOwnerRelation = {
      relation: '',
      sort: 0,
      display: false,
    }

    const onUpdate = async () => {
      editingItem.value = null;
      data.value = await fetchOwnerRelations();
      setMessage({ type: 'success', message: '更新しました。' })

    }
    const onDelete = async (id: number) => {
      const data = await deleteOwnerRelation(id)
      setMessage({ type: 'success', message: '削除しました。' })
      
    }

    return {
      onUpdate,
      data,
      editingItem,
      addTemplate,
      onChangeDisplay,
      onDelete
    }
  }
})
</script>