
import { computed, defineComponent, isProxy, onMounted, ref, SetupContext, } from 'vue';
import { IOwnerRelation, IPurpose } from '@/types/Interfaces';
import useOwnerRelation from '@/types/OwnerRelation';
import useMasterData from '@/types/MasterData';

import OwnerRelationEditForm from './OwnerRelationEditForm.vue';
import useFlashMessage from '@/types/FlashMessage';


export default defineComponent({
  components: {
    OwnerRelationEditForm
  },
  setup(_, context: SetupContext) {
    // const headers = g
    const {
      setMessage   
    } = useFlashMessage();

    const {
      data,
      editingItem,
      onChangeDisplay
    } = useMasterData();
    
    
    const {
      fetchOwnerRelations,
      deleteOwnerRelation
    } = useOwnerRelation();
    
    onMounted(async () => {
      data.value = await fetchOwnerRelations();
      console.log(data.value);
    })
    
    const addTemplate: IOwnerRelation = {
      relation: '',
      sort: 0,
      display: false,
    }

    const onUpdate = async () => {
      editingItem.value = null;
      data.value = await fetchOwnerRelations();
      setMessage({ type: 'success', message: '更新しました。' })

    }
    const onDelete = async (id: number) => {
      const data = await deleteOwnerRelation(id)
      setMessage({ type: 'success', message: '削除しました。' })
      
    }

    return {
      onUpdate,
      data,
      editingItem,
      addTemplate,
      onChangeDisplay,
      onDelete
    }
  }
})
